import React from 'react';
import { useHistory } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, Popover, Card, CardHeader, Avatar, CardActions, Button } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  card: {
    minWidth: 300,
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
}));

function CustomAppBar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  let history = useHistory();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_ITEM);
    history.replace('/');
  }

  const doctorUser = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_ITEM)).doctorUser;

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" className={classes.title}>DrApp醫生版 {props.title ? props.title : ''}</Typography>
        <IconButton
          aria-label="Doctor User"
          aria-controls="doctor-user-appbar-card"
          aria-haspopup="true"
          onClick={handleClick}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Popover
          id="doctor-user-appbar-card"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          <Card className={classes.card}>
            <CardHeader avatar={<Avatar aria-label="doctor" src={doctorUser.doctor.profileImageUrl} alt={doctorUser.doctor.fullTitle} />} title={doctorUser.name} />
            <CardActions className={classes.cardActions}>
              <Button onClick={logout}>Logout</Button>
            </CardActions>
          </Card>
        </Popover>
      </Toolbar>
    </AppBar>
  );
}

export default CustomAppBar;