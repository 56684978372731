import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Container, Paper, Typography, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const LOGIN_DOC = gql`
  mutation loginDoc($email: String!, $password: String!) {
    loginDoc(email: $email, password: $password) {
      user {
        name
        doctor {
          id
          fullTitle
          profileImageUrl
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(10),
    padding: theme.spacing(3, 2),
  },
  error: {
    color: theme.palette.error.main,
  },
}));

function LoginPage() {
  const classes = useStyles();

  let history = useHistory();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/" } };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [loginDoc, { loading: loginDocLoading, error: loginDocError }] = useMutation(LOGIN_DOC, {
    onCompleted(data) {
      localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_ITEM, JSON.stringify({
        doctorUser: data.loginDoc.user,
        expiry: Date.now() + 2592000000,
      }));
      history.replace(from);
    }
  });

  const handleSubmit = e => {
    e.preventDefault();

    loginDoc({
      variables: {
        email,
        password,
      }
    });
  }

  return (
    <Container maxWidth="xs">
      <Paper className={classes.root}>
        {loginDocError && loginDocError.graphQLErrors.map(({ message }, i) => <div key={i} className={classes.error}>{message}</div>)}
        <form onSubmit={handleSubmit}>
          <Typography variant="h6">DrApp醫生版 登入</Typography>
          <TextField
            required
            id="email"
            label="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            required
            id="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <Button type="submit" variant="contained" color="primary" disabled={loginDocLoading}>Login</Button>
        </form>
      </Paper>
    </Container>
  );
}

export default LoginPage;
