import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';

function PrivateRoute({ children, ...rest }) {
  const isAuthenticated = () => {
    let data = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_ITEM));
    return data && (!data.expiry || data.expiry > Date.now());
  }

  return (
    <Route
      {...rest}
      render={({ location }) => isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;