import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Modal, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, LinearProgress, CircularProgress, Tabs, Tab, List, ListItem, Typography, Chip, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid, Avatar, Menu, MenuItem, Link, Button, InputBase, InputAdornment, IconButton } from '@material-ui/core';
import { ExpandMore, Assignment, Forward, Image, Send } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import CustomAppBar from '../components/CustomAppBar';
import moment from 'moment';
import uuidv4 from 'uuid/v4';
import { toLocaleDatetime } from '../utils';

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    display: 'flex',
  },
  questionListWrapper: {
    width: 340,
    flexShrink: 0,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
  questionList: {
    position: 'relative',
    height: 'calc(100vh - 112px)',
    overflowY: 'auto',
  },
  noQuestionsMessage: {
    margin: theme.spacing(1),
    textAlign: 'center',
  },
  selectedQuestionLink: {
    backgroundColor: theme.palette.action.selected,
  },
  questionWrapper: {
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 64px)',
  },
  questionContentWrapper: {
    flexGrow: 1,
    padding: theme.spacing(1),
    overflowY: 'auto',
  },
  selectQuestionMessageWrapper: {
    height: '100%',
  },
  questionContent: {
    padding: theme.spacing(1),
  },
  questionCloseAlert: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.error.light,
    color: theme.palette.common.white,
    textAlign: 'center',
  },
  questionAnswerContentWrapper: {
    margin: theme.spacing(2, 0),
    '&::after': {
      content: '""',
      display: 'block',
      clear: 'both',
    },
  },
  questionAnswerDoctorContent: {
    position: 'relative',
    maxWidth: '75%',
    padding: theme.spacing(1.5, 2.5),
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
    float: 'right',
  },
  questionAnswerUserContent: {
    position: 'relative',
    maxWidth: '75%',
    padding: theme.spacing(1.5, 2.5),
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    float: 'left',
  },
  questionAnswerContentMenuButton: {
    position: 'absolute',
    top: theme.spacing(0.25),
    right: theme.spacing(0.25),
    padding: 0,
  },
  questionCloseRemark: {
    margin: theme.spacing(1, 0.5),
    color: theme.palette.grey[600],
    textAlign: 'center',
  },
  questionClosingRemark: {
    margin: theme.spacing(1, 0.5),
    color: theme.palette.error.light,
    textAlign: 'center',
  },
  questionReplyWrapper: {
    flexShrink: 0,
    display: 'flex',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
  },
  startAnswerQuestionButton: {
    backgroundColor: theme.palette.common.white,
  },
  addImageAnswerContentButton: {
    flexShrink: 0,
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    '& *': {
      cursor: 'pointer',
    }
  },
  questionReply: {
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  profileImage: {
    display: 'inline-block',
    width: '2rem',
    height: '2rem',
  },
  age: {
    color: theme.palette.grey[500],
  },
  gender: {
    fontFamily: '"Roboto"',
    fontWeight: 'bold',
    '& .male': {
      color: '#65a0e0',
    },
    '& .female': {
      color: '#f66485',
    },
  },
  doctorUnreadContentCount: {
    width: '1.2rem',
    height: '1.2rem',
    borderRadius: '50%',
    backgroundColor: theme.palette.error.light,
    color: theme.palette.common.white,
    lineHeight: '1.2rem',
    textAlign: 'center',
  },
  specialty: {
    marginRight: '0.5rem',
  },
  relatedInfoTitle: {
    marginTop: theme.spacing(1),
    fontWeight: 700,
  },
  img: {
    maxWidth: '100%',
    maxHeight: '200px',
    cursor: 'pointer',
  },
  lightboxImageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      maxWidth: '90%',
      maxHeight: '90%',
      outline: 0,
    }
  },
  progressWrapper: {
    position: 'relative',
  },
  iconButtonProgress: {
    position: 'absolute',
    top: theme.spacing(0.5),
    left: theme.spacing(0.5),
    zIndex: 1,
  },
  startAnswerQuestionButtonProgressWrapper: {
    position: 'relative',
    width: '100%',
  },
  startAnswerQuestionButtonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-0.5rem',
    marginLeft: '-3.5rem',
  },
  linearProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
  },
  medicalInfoDialog: {
    '& .MuiDialog-paper': {
      width: 500,
    },
  },
  medicalInfoTitle: {
    marginTop: '1rem',
  },
  medicalInfoDescription: {
    color: theme.palette.primary.main,
  },
  preWrapParagraph: {
    whiteSpace: 'pre-wrap',
  },
}));

const APP_SETTINGS = gql`
  query {
    appSettings {
      questionLifeTimeMinutes
    }
  }
`

const LOCALIZED_DATAS = gql`
  query {
    localizedDatas {
      id
      key
      data
    }
  }
`

const UNANSWERED_QUESTIONS = gql`
  query unansweredQuestions {
    unansweredQuestions {
      id
      createdAt
      user {
        id
        name
        gender
        age
      }
      title
      specialties
      {
        id
        name
      }
    }
  }
`;

const MY_ANSWERS = gql`
  query myAnswers($orderBy: QuestionAnswerOrderByInput) {
    myAnswers(orderBy: $orderBy) {
      id
      question {
        id
        createdAt
        user {
          id
          name
          gender
          age
        }
        title
        specialties
        {
          id
          name
        }
        isAnswerClosed
        answerCloseAt
      }
      doctorUnreadContentCount
    }
  }
`;

const UNANSWERED_QUESTION = gql`
  query unansweredQuestion($where: QuestionWhereInput) {
    unansweredQuestions(where: $where) {
      id
      createdAt
      user {
        id
        name
        gender
        age
        profileImageUrl
          medicalInfos {
            __typename
            id
            infoType
            isArchived
            ... on UserMedicalInfoNumeric {
              numericValue
            }
            ... on UserMedicalInfoBoolean {
              booleanValue
            }
            ... on UserMedicalInfoText {
              textTitle
              textValue
            }
            ... on UserMedicalInfoPastInfo {
              pastInfo
              pastStartDate
              pastEndDate
              pastDisease {
                id
                name
              }
              pastSymptom {
                id
                name
              }
            }
            ... on UserMedicalInfoFamilyInfo {
              familyUserId
              familyName
              familyRelationship
              familyInfoTitle
              familyInfo
              familyDisease {
                id
                name
              }
              familySymptom {
                id
                name
              }
            }
          }
      }
      title
      content
      voice {
        id
        path
      }
      imgs {
        id
        path
      }
      specialties
      {
        id
        name
      }
      relatedInfos {
        id
        title
        choice
        description
        voice {
          id
          path
        }
        imgs {
          id
          path
        }
      }
    }
  }
`;

const MY_ANSWER = gql`
  query myAnswer($where: QuestionAnswerWhereInput) {
    myAnswers(where: $where) {
      id
      confirmedAt
      question {
        id
        createdAt
        user {
          id
          name
          gender
          age
          profileImageUrl
          medicalInfos {
            __typename
            id
            infoType
            isArchived
            ... on UserMedicalInfoNumeric {
              numericValue
            }
            ... on UserMedicalInfoBoolean {
              booleanValue
            }
            ... on UserMedicalInfoText {
              textTitle
              textValue
            }
            ... on UserMedicalInfoPastInfo {
              pastInfo
              pastStartDate
              pastEndDate
              pastDisease {
                id
                name
              }
              pastSymptom {
                id
                name
              }
            }
            ... on UserMedicalInfoFamilyInfo {
              familyUserId
              familyName
              familyRelationship
              familyInfoTitle
              familyInfo
              familyDisease {
                id
                name
              }
              familySymptom {
                id
                name
              }
            }
          }
        }
        title
        content
        voice {
          id
          path
        }
        imgs {
          id
          path
        }
        specialties
        {
          id
          name
        }
        relatedInfos {
          id
          title
          choice
          description
          voice {
            id
            path
          }
          imgs {
            id
            path
          }
        }
        isAnswerClosed
        answerCloseAt
      }
      contents(where: {
        isRemoved: false,
      }) {
        id
        createdAt
        isFromUser
        content
        file {
          id
          path
        }
        fileType
      }
      lastReplyAt
      doctorUnreadContentCount
    }
  }
`;

const START_ANSWER_QUESTION = gql`
  mutation startAnswerQuestion($questionId: ID!) {
    startAnswerQuestion(questionId: $questionId) {
      id
      question {
        id
        createdAt
        user {
          id
          name
          gender
          age
          profileImageUrl
          medicalInfos {
            __typename
            id
            infoType
            isArchived
            ... on UserMedicalInfoNumeric {
              numericValue
            }
            ... on UserMedicalInfoBoolean {
              booleanValue
            }
            ... on UserMedicalInfoText {
              textTitle
              textValue
            }
            ... on UserMedicalInfoPastInfo {
              pastInfo
              pastStartDate
              pastEndDate
              pastDisease {
                id
                name
              }
              pastSymptom {
                id
                name
              }
            }
            ... on UserMedicalInfoFamilyInfo {
              familyUserId
              familyName
              familyRelationship
              familyInfoTitle
              familyInfo
              familyDisease {
                id
                name
              }
              familySymptom {
                id
                name
              }
            }
          }
        }
        title
        content
        voice {
          id
          path
        }
        imgs {
          id
          path
        }
        specialties
        {
          id
          name
        }
        relatedInfos {
          id
          title
          choice
          description
          voice {
            id
            path
          }
          imgs {
            id
            path
          }
        }
        isAnswerClosed
        answerCloseAt
      }
      contents(where: {
        isRemoved: false,
      }) {
        id
        createdAt
        isFromUser
        content
        file {
          id
          path
        }
        fileType
      }
    }
  }
`;

const MARK_QUESTION_ANSWER_READ = gql`
  mutation markQuestionAnswerRead($answerId: ID!, $readUntil: DateTime!) {
    markQuestionAnswerRead(answerId: $answerId, readUntil: $readUntil) {
      id
      doctorUnreadContentCount
    }
  }
`;

const ADD_TEXT_ANSWER_CONTENT = gql`
  mutation addTextAnswerContent($answerId: ID!, $content: String!) {
    addTextAnswerContent(answerId: $answerId, content: $content) {
      id
      createdAt
      answer {
        id
        question {
          id
          answerCloseAt
        }
      }
      isFromUser
      content
      file {
        id
        path
      }
      fileType
    }
  }
`;

const ADD_IMAGE_ANSWER_CONTENT = gql`
  mutation addImageAnswerContent($answerId: ID!, $upload: Upload!) {
    addImageAnswerContent(answerId: $answerId, upload: $upload) {
      id
      createdAt
      answer {
        id
        question {
          id
          answerCloseAt
        }
      }
      isFromUser
      content
      file {
        id
        path
      }
      fileType
    }
  }
`;

const REMOVE_ANSWER_CONTENT = gql`
mutation removeAnswerContent($contentId: ID!) {
  removeAnswerContent(contentId: $contentId) {
    id
  }
}
`;

function QuestionList(props) {
  const classes = useStyles();

  return (
    <>
      {props.loading && <LinearProgress className={classes.linearProgress} />}
      <List component="nav">
        {props.data && props.data.unansweredQuestions && props.data.unansweredQuestions.length === 0 && (
          <Typography className={classes.noQuestionsMessage} variant="subtitle1">未有問題</Typography>
        )}
        {props.data && props.data.unansweredQuestions && props.data.unansweredQuestions.map(unansweredQuestion => (
          <ListItem key={unansweredQuestion.id} className={unansweredQuestion.id === props.questionId ? classes.selectedQuestionLink : classes.questionLink} button divider onClick={() => props.handleClick(unansweredQuestion.id)}>
            <QuestionListItem question={unansweredQuestion} />
          </ListItem>
        ))}
        {props.data && props.data.myAnswers && props.data.myAnswers.length === 0 && (
          <Typography className={classes.noQuestionsMessage} variant="subtitle1">未有問題</Typography>
        )}
        {props.data && props.data.myAnswers && props.data.myAnswers.map(myAnswer => (
          <ListItem key={myAnswer.id} className={myAnswer.id === props.questionAnswerId ? classes.selectedQuestionLink : classes.questionLink} button divider onClick={() => props.handleClick(myAnswer.id)}>
            <QuestionListItem question={myAnswer.question} doctorUnreadContentCount={myAnswer.doctorUnreadContentCount} />
          </ListItem>
        ))}
      </List>
    </>
  );
}

function QuestionListItem(props) {
  const classes = useStyles();

  const now = new Date().toISOString();

  return (
    <div>
      <Grid container spacing={1} justify="flex-start" alignItems="center">
        {props.question.user.profileImageUrl && (
          <Grid item>
            <Avatar className={classes.profileImage} alt={props.question.user.name} src={props.question.user.profileImageUrl} />
          </Grid>
        )}
        <Grid item>
          <Typography variant="subtitle2">{props.question.user.name}</Typography>
        </Grid>
        {props.question.user.age !== null && (
          <Grid item>
            <Typography variant="subtitle2" className={classes.age}>{props.question.user.age}</Typography>
          </Grid>
        )}
        {props.question.user.gender && (
          <Grid item>
            <Typography variant="subtitle2" className={classes.gender}>{props.question.user.gender === 'MALE' ? <span className="male" role="img" aria-label="Male">♂</span> : (props.question.user.gender === 'FEMALE' ? <span className="female" role="img" aria-label="Female">♀</span> : <span>{props.question.user.gender}</span>)}</Typography>
          </Grid>
        )}
        {props.doctorUnreadContentCount > 0 && (
          <Grid item>
            <Typography variant="subtitle2" className={classes.doctorUnreadContentCount}>{props.doctorUnreadContentCount}</Typography>
          </Grid>
        )}
      </Grid>
      <Typography variant="subtitle1" className={classes.questionTitle}>{props.question.title}</Typography>
      <div>
        {props.question.specialties && props.question.specialties.map(specialty => (
          <Chip key={specialty.id} size="small" color="primary" variant="outlined" className={classes.specialty} label={specialty.name} />
        ))}
      </div>
      <Typography variant="caption" className={classes.questionTitle}>{toLocaleDatetime(props.question.createdAt)}</Typography>
      {props.question.isAnswerClosed && (
        <Typography variant="caption" className={classes.questionCloseRemark}>已完成</Typography>
      )}
      {props.question.answerCloseAt > now && (
        <Typography variant="caption" className={classes.questionClosingRemark}>解答倒數中</Typography>
      )}
    </div>
  );
}

function Question(props) {
  const doctorId = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_ITEM)).doctorUser.doctor.id;

  const classes = useStyles();

  const [questionContentWrapper, setQuestionContentWrapper] = useState();

  let question;
  if (props.data) {
    if (props.data.unansweredQuestions) {
      question = props.data.unansweredQuestions[0];
    } else if (props.data.myAnswers) {
      question = props.data.myAnswers[0].question;
    }
  }
  if (question && question.relatedInfos.filter(relatedInfo => relatedInfo.title === 'questionRelatedInfoTitleContentText').length === 0) question.relatedInfos.splice(1, 0, { id: question.id, title: 'questionRelatedInfoTitleContentText', description: question.content, voice: question.voice });

  const [answerCloseAt, setAnswerCloseAt] = useState();
  useEffect(() => {
    if (question) setAnswerCloseAt(question.answerCloseAt);
  }, [question]);

  let medicalInfos = {};
  if (question && question.user.medicalInfos.length) {
    question.user.medicalInfos.forEach(medicalInfo => {
      switch (medicalInfo.infoType) {
        case 'N_BMI':
          medicalInfos['N_BMI'] = medicalInfo.numericValue;
          break;
        case 'T_OTHER':
          let textValue;
          try {
            textValue = JSON.parse(medicalInfo.textValue);
          } catch(error) {
            textValue = {};
          }
          if ('healthHabit' in textValue && textValue['healthHabit'].length > 0) medicalInfos['T_OTHER_healthHabit'] = textValue['healthHabit'];
          if ('foodAllergen' in textValue && textValue['foodAllergen'].length > 0) medicalInfos['T_OTHER_foodAllergen'] = textValue['foodAllergen'];
          if ('bodyPain' in textValue && ((textValue['bodyPain'].front && textValue['bodyPain'].front.length > 0) || (textValue['bodyPain'].back && textValue['bodyPain'].back.length))) medicalInfos['T_OTHER_bodyPain'] = textValue['bodyPain'];
          if ('vaccine' in textValue && textValue['vaccine'].length > 0) medicalInfos['T_OTHER_vaccine'] = textValue['vaccine'];
          if ('regularMedicine' in textValue && textValue['regularMedicine'].length > 0) medicalInfos['T_OTHER_regularMedicine'] = textValue['regularMedicine'];
          break;
        case 'FAMILY_INFO':
          if (medicalInfo.familyDisease) {
            if (!('FAMILY_INFO_familyDisease' in medicalInfos)) medicalInfos['FAMILY_INFO_familyDisease'] = [];
            medicalInfos['FAMILY_INFO_familyDisease'].push(medicalInfo.familyDisease);
          }
          break;
        default:
      }
    });
    if ('N_BMI' in medicalInfos) {
      switch (true) {
        case (medicalInfos['N_BMI'] < 18.5):
          medicalInfos['N_BMI'] = `${medicalInfos['N_BMI']} (體重過輕)`;
          break;
        case (medicalInfos['N_BMI'] < 25):
          medicalInfos['N_BMI'] = `${medicalInfos['N_BMI']} (正常)`;
          break;
        case (medicalInfos['N_BMI'] < 30):
          medicalInfos['N_BMI'] = `${medicalInfos['N_BMI']} (過重)`;
          break;
        case (medicalInfos['N_BMI'] < 35):
          medicalInfos['N_BMI'] = `${medicalInfos['N_BMI']} (輕度肥胖)`;
          break;
        case (medicalInfos['N_BMI'] < 40):
          medicalInfos['N_BMI'] = `${medicalInfos['N_BMI']} (中度肥胖)`;
          break;
        case (medicalInfos['N_BMI'] >= 40):
          medicalInfos['N_BMI'] = `${medicalInfos['N_BMI']} (重度肥胖)`;
          break;
        default:
      }
    }
    if ('T_OTHER_healthHabit' in medicalInfos) {
      let score = medicalInfos['T_OTHER_healthHabit'].reduce((totalScore, healthHabit) => totalScore + healthHabit.score, 0);
      switch (true) {
        case (score <= 0):
          medicalInfos['T_OTHER_healthHabit'] = '惡劣';
          break;
        case (score === 1):
          medicalInfos['T_OTHER_healthHabit'] = '差';
          break;
        case (score === 2):
          medicalInfos['T_OTHER_healthHabit'] = '一般';
          break;
        case (score === 3):
          medicalInfos['T_OTHER_healthHabit'] = '良好';
          break;
        case (score >= 4):
          medicalInfos['T_OTHER_healthHabit'] = '優良';
          break;
        default:
      }
    }
  }

  const [contents, setContents] = useState();
  useEffect(() => {
    setContents(props.data && props.data.myAnswers ? props.data.myAnswers[0].contents : undefined);
  }, [props.data]);

  const [isMedicalInfoDialogOpen, setIsMedicalInfoDialogOpen] = useState(false);
  const showMedicalInfo = e => {
    e.stopPropagation();

    setIsMedicalInfoDialogOpen(true);
  }
  const hideMedicalInfo = () => {
    setIsMedicalInfoDialogOpen(false);
  }

  const [lightboxImageUrl, setLightboxImageUrl] = useState();

  const [focusedAnswerContentId, setFocusedAnswerContentId] = useState();
  const [selectedAnswerContentId, setSelectedAnswerContentId] = useState();
  const [answerContentMenuButton, setAnswerContentMenuButton] = useState();
  const openAnswerContentMenu = (e, focusedAnswerContentId) => {
    setSelectedAnswerContentId(focusedAnswerContentId);
    setAnswerContentMenuButton(e.currentTarget);
  }
  const closeAnswerContentMenu = e => {
    setSelectedAnswerContentId(undefined);
    setAnswerContentMenuButton(undefined);
  }

  const [startAnswerQuestion, { loading: loadingStartAnswerQuestion }] = useMutation(START_ANSWER_QUESTION, {
    onCompleted: data => props.onCompletedStartAnswerQuestion(data)
  });

  const [textAnswerContent, setTextAnswerContent] = useState('');
  const [addTextAnswerContent, { loading: loadingAddTextAnswerContent }] = useMutation(ADD_TEXT_ANSWER_CONTENT, {
    onCompleted: data => {
      setTextAnswerContent('');
      setContents(contents.concat([data.addTextAnswerContent]));
      if (questionContentWrapper && questionContentWrapper.scrollTo) questionContentWrapper.scrollTo({ top: questionContentWrapper.clientHeight, behavior: 'smooth' });
      let newAnswerCloseAt = data.addTextAnswerContent.answer.question.answerCloseAt || new Date(Date.now() + (props.appSettings ? props.appSettings.appSettings.questionLifeTimeMinutes * 60000 : 43200000)).toISOString();
      setAnswerCloseAt(newAnswerCloseAt);
      props.updateQuestionItem(props.data.myAnswers[0].id, newAnswerCloseAt);
    }
  });
  const [addImageAnswerContent, { loading: loadingAddImageAnswerContent }] = useMutation(ADD_IMAGE_ANSWER_CONTENT, {
    onCompleted: data => {
      setContents(contents.concat([data.addImageAnswerContent]));
      let newAnswerCloseAt = data.addImageAnswerContent.answer.question.answerCloseAt || new Date(Date.now() + (props.appSettings ? props.appSettings.appSettings.questionLifeTimeMinutes * 60000 : 43200000)).toISOString();
      setAnswerCloseAt(newAnswerCloseAt);
      props.updateQuestionItem(props.data.myAnswers[0].id, newAnswerCloseAt);
    }
  });
  const [removeAnswerContent] = useMutation(REMOVE_ANSWER_CONTENT, {
    onCompleted: data => {
      setContents(contents.filter(v => v.id !== data.removeAnswerContent.id));
    }
  });

  return (
    <>
      {props.loading && <LinearProgress className={classes.linearProgress} />}
      {lightboxImageUrl && (
        <Modal
          className={classes.lightboxImageWrapper}
          open={Boolean(lightboxImageUrl)}
          onClose={() => setLightboxImageUrl(undefined)}
        >
          <img src={lightboxImageUrl} alt="Attachment" />
        </Modal>
      )}
      {question && (
        <Dialog className={classes.medicalInfoDialog} onClose={hideMedicalInfo} aria-labelledby="medicalInfo" open={isMedicalInfoDialogOpen}>
          <DialogTitle id="medicalInfo">個人醫療檔案</DialogTitle>
          <DialogContent>
            {question.user.medicalInfos.length === 0 && (
              <Typography variant="subtitle1">沒有資料</Typography>
            )}
            {medicalInfos['N_BMI'] && (
              <>
                <Typography className={classes.medicalInfoTitle} variant="subtitle1">BMI</Typography>
                <Typography className={classes.medicalInfoDescription} variant="subtitle2">{medicalInfos['N_BMI']}</Typography>
              </>
            )}
            {medicalInfos['T_OTHER_healthHabit'] && (
              <>
                <Typography className={classes.medicalInfoTitle} variant="subtitle1">健康習慣</Typography>
                <Typography className={classes.medicalInfoDescription} variant="subtitle2">{medicalInfos['T_OTHER_healthHabit']}</Typography>
              </>
            )}
            {medicalInfos['T_OTHER_foodAllergen'] && (
              <>
                <Typography className={classes.medicalInfoTitle} variant="subtitle1">致敏食物</Typography>
                <Typography className={classes.medicalInfoDescription} variant="subtitle2">{medicalInfos['T_OTHER_foodAllergen'].map(foodAllergen => foodAllergen.name in props.localizedDatas ? props.localizedDatas[foodAllergen.name].data : foodAllergen.name).join(', ')}</Typography>
              </>
            )}
            {medicalInfos['T_OTHER_bodyPain'] && (
              <>
                <Typography className={classes.medicalInfoTitle} variant="subtitle1">痛症</Typography>
                <Typography className={classes.medicalInfoDescription} variant="subtitle2">{[].concat(medicalInfos['T_OTHER_bodyPain'].front, medicalInfos['T_OTHER_bodyPain'].back).map(bodyPain => bodyPain.name || bodyPain.id).join(', ')}</Typography>
              </>
            )}
            {medicalInfos['T_OTHER_vaccine'] && (
              <>
                <Typography className={classes.medicalInfoTitle} variant="subtitle1">疫苗接種紀錄</Typography>
                {medicalInfos['T_OTHER_vaccine'].map(vaccine => (
                  <Typography key={vaccine.id} className={classes.medicalInfoDescription} variant="subtitle2">{vaccine.name in props.localizedDatas ? props.localizedDatas[vaccine.name].data : vaccine.name}</Typography>
                ))}
              </>
            )}
            {medicalInfos['T_OTHER_regularMedicine'] && (
              <>
                <Typography className={classes.medicalInfoTitle} variant="subtitle1">定期服用藥物或保健藥</Typography>
                {medicalInfos['T_OTHER_regularMedicine'].map(regularMedicine => (
                  <Typography key={regularMedicine.id} className={classes.medicalInfoDescription} variant="subtitle2">{`${regularMedicine.name} (${regularMedicine.schedule in props.localizedDatas ? props.localizedDatas[regularMedicine.schedule].data : regularMedicine.schedule})`}</Typography>
                ))}
              </>
            )}
            {medicalInfos['FAMILY_INFO_familyDisease'] && (
              <>
                <Typography className={classes.medicalInfoTitle} variant="subtitle1">家族病史</Typography>
                {medicalInfos['FAMILY_INFO_familyDisease'].map(familyDisease => (
                  <Typography key={familyDisease.id} className={classes.medicalInfoDescription} variant="subtitle2">{familyDisease.name}</Typography>
                ))}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={hideMedicalInfo} variant="outlined" color="primary">關閉</Button>
          </DialogActions>
        </Dialog>
      )}
      {answerCloseAt > new Date().toISOString() && (
        <QuestionCloseAlert answerCloseAt={answerCloseAt} />
      )}
      <div className={classes.questionContentWrapper} ref={element => setQuestionContentWrapper(element)}>
        {!question && (
          <Grid container className={classes.selectQuestionMessageWrapper} justify="center" alignItems="center">
            <Grid item>
              <Typography variant="h5">請選擇問題</Typography>
            </Grid>
          </Grid>
        )}
        {question && (
          <ExpansionPanel defaultExpanded className={classes.questionContent}>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <div>
                <Grid container spacing={1} justify="flex-start" alignItems="center">
                  {question.user.profileImageUrl && (
                    <Grid item>
                      <Avatar className={classes.profileImage} alt={question.user.name} src={question.user.profileImageUrl} />
                    </Grid>
                  )}
                  <Grid item>
                    <Typography variant="subtitle2">{question.user.name}</Typography>
                  </Grid>
                  {question.user.age !== null && (
                    <Grid item>
                      <Typography variant="subtitle2" className={classes.age}>{question.user.age}</Typography>
                    </Grid>
                  )}
                  {question.user.gender && (
                    <Grid item>
                      <Typography variant="subtitle2" className={classes.gender}>{question.user.gender === 'MALE' ? <span className="male" role="img" aria-label="Male">♂</span> : (question.user.gender === 'FEMALE' ? <span className="female" role="img" aria-label="Female">♀</span> : <span>{question.user.gender}</span>)}</Typography>
                    </Grid>
                  )}
                  <Tooltip title="檢視個人醫療檔案">
                    <IconButton aria-label="檢視個人醫療檔案" onClick={showMedicalInfo}>
                      <Assignment />
                    </IconButton>
                  </Tooltip>
                  <Grid item>
                    <Typography variant="caption">{toLocaleDatetime(question.createdAt)}</Typography>
                  </Grid>
                  {question.specialties && (
                    <Grid item>
                      {question.specialties.map(specialty => (
                        <Chip key={specialty.id} size="small" color="primary" variant="outlined" className={classes.specialty} label={specialty.name} />
                      ))}
                    </Grid>
                  )}
                </Grid>
                <Typography variant="subtitle1" className={classes.preWrapParagraph}>{question.title}</Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>
                {question.relatedInfos.map(relatedInfo => (
                  <div key={relatedInfo.id}>
                    <Typography variant="subtitle1" className={classes.relatedInfoTitle}>{props.localizedDatas && relatedInfo.title in props.localizedDatas ? props.localizedDatas[relatedInfo.title].data : relatedInfo.title}</Typography>
                    {relatedInfo.choice && (
                      <div>{props.localizedDatas && relatedInfo.choice in props.localizedDatas ? props.localizedDatas[relatedInfo.choice].data : relatedInfo.choice}</div>
                    )}
                    {relatedInfo.description && (
                      <div className={classes.preWrapParagraph}>{props.localizedDatas && relatedInfo.description in props.localizedDatas ? props.localizedDatas[relatedInfo.description].data : relatedInfo.description}</div>
                    )}
                    {relatedInfo.voice && (
                      <div>
                        <audio controls>
                          <source src={relatedInfo.voice.path} />
                          <div><a href={relatedInfo.voice.path}>Voice File</a></div>
                        </audio>
                      </div>
                    )}
                    {relatedInfo.imgs && relatedInfo.imgs.length ? (
                      <div>{relatedInfo.imgs.map(img => (
                        <img key={img.id} className={classes.img} src={img.path} onClick={() => setLightboxImageUrl(img.path)} alt="Attachment" />
                      ))}</div>
                    ) : null}
                    {relatedInfo.choice || relatedInfo.description || relatedInfo.voice || (relatedInfo.imgs && relatedInfo.imgs.length) ? null : <div>沒有提供</div>}
                  </div>
                ))}
                {question.imgs && question.imgs.length ? (
                  <div>
                    <Typography variant="subtitle1" className={classes.relatedInfoTitle}>{props.localizedDatas && 'questionRelatedInfoTitleFiles' in props.localizedDatas ? props.localizedDatas['questionRelatedInfoTitleFiles'].data : 'questionRelatedInfoTitleFiles'}</Typography>
                    <div>{question.imgs.map(img => (
                      <img key={img.id} className={classes.img} src={img.path} onClick={() => setLightboxImageUrl(img.path)} alt="Attachment" />
                    ))}</div>
                  </div>
                ) : null}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
        {contents && (
          <>
            <Menu
              id="answer-content-menu"
              anchorEl={answerContentMenuButton}
              open={Boolean(answerContentMenuButton)}
              onClose={closeAnswerContentMenu}
            >
              <MenuItem onClick={() => {
                setSelectedAnswerContentId(undefined);
                setAnswerContentMenuButton(undefined);
                if (selectedAnswerContentId) removeAnswerContent({ variables: { contentId: selectedAnswerContentId }});
              }}>刪除</MenuItem>
            </Menu>
            <>
              {contents.map(content => {
                const data = content.content ? JSON.parse(content.content)[0] : {};

                return (
                  <div key={content.id} className={classes.questionAnswerContentWrapper}>
                    <div className={content.isFromUser ? classes.questionAnswerUserContent : classes.questionAnswerDoctorContent} onMouseOver={() => setFocusedAnswerContentId(content.id)} onMouseLeave={() => setFocusedAnswerContentId(undefined)}>
                      {question && !question.isAnswerClosed && focusedAnswerContentId === content.id && (
                        <IconButton className={classes.questionAnswerContentMenuButton} size="small" aria-label="Open Menu" onClick={e => openAnswerContentMenu(e, content.id)}>
                          <ExpandMore />
                        </IconButton>
                      )}
                      <div className={classes.preWrapParagraph}>{data.text}</div>
                      {data.recommendToSpecialty && (
                        <>
                          <Forward />
                          <Typography variant="h6">Recommend to {content.recommendToSpecialty.name ? content.recommendToSpecialty.name : 'Unknown'}</Typography>
                        </>
                      )}
                      {content.fileType === 'IMAGE' && content.file && (
                        <img className={classes.img} src={content.file.path} onClick={() => setLightboxImageUrl(content.file.path)} alt="Attachment" />
                      )}
                      {content.fileType === 'VOICE' && content.file && (
                        <audio controls>
                          <source src={content.file.path} />
                          <Link href={content.file.path}>Voice File</Link>
                        </audio>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          </>
        )}
        {question && question.isAnswerClosed && (
          <Typography className={classes.questionCloseRemark} variant="caption" component="div">
            <div>完結對話</div>
            <div>{toLocaleDatetime(question.answerCloseAt)}</div>
          </Typography>
        )}
      </div>
      {question && (
        <div className={classes.questionReplyWrapper}>
          {props.data.unansweredQuestions && (
            <div className={classes.startAnswerQuestionButtonProgressWrapper}>
              <Button className={classes.startAnswerQuestionButton} variant="contained" fullWidth size="large" disabled={loadingStartAnswerQuestion} onClick={() => startAnswerQuestion({ variables: { questionId: question.id }})}>回答問題</Button>
              {loadingStartAnswerQuestion && <CircularProgress size="1rem" className={classes.startAnswerQuestionButtonProgress} />}
            </div>
          )}
          {props.data.myAnswers && (
            <>
              <span className={classes.progressWrapper}>
                <IconButton className={classes.addImageAnswerContentButton} aria-label="Upload Image" color="primary" disabled={question.isAnswerClosed || loadingAddTextAnswerContent || loadingAddImageAnswerContent}>
                  <label>
                    <input type="file" accept="image/png, image/jpeg" style={{ display: 'none' }} disabled={question.isAnswerClosed || loadingAddTextAnswerContent || loadingAddImageAnswerContent} onChange={e => {
                      const file = e.target.files[0];

                      addImageAnswerContent({
                        variables: {
                          answerId: props.data.myAnswers[0].id,
                          upload: file,
                        }
                      })
                    }} />
                    <Image />
                  </label>
                </IconButton>
                {loadingAddImageAnswerContent && <CircularProgress className={classes.iconButtonProgress} />}
              </span>
              <InputBase
                className={classes.questionReply}
                multiline
                rows="10"
                rowsMax="10"
                autoFocus
                disabled={question.isAnswerClosed || loadingAddTextAnswerContent || loadingAddImageAnswerContent} endAdornment={
                  <InputAdornment position="end">
                    <span className={classes.progressWrapper}>
                      <IconButton aria-label="Send Text Message" color="primary" disabled={question.isAnswerClosed || loadingAddTextAnswerContent || loadingAddImageAnswerContent || textAnswerContent.trim().length === 0} onClick={() => addTextAnswerContent({
                        variables: {
                          answerId: props.data.myAnswers[0].id,
                          content: JSON.stringify([
                            {
                              text: textAnswerContent.trim(),
                              user: {
                                _id: doctorId,
                              },
                              createdAt: new Date().toISOString(),
                              _id: uuidv4(),
                            }
                          ]),
                        }
                      }) }>
                        <Send />
                      </IconButton>
                      {loadingAddTextAnswerContent && <CircularProgress className={classes.iconButtonProgress} />}
                    </span>
                  </InputAdornment>
                }
                value={textAnswerContent}
                onChange={e => setTextAnswerContent(e.target.value)}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}

function QuestionCloseAlert(props) {
  const classes = useStyles();

  const [timeLeft, setTimeLeft] = useState(props.answerCloseAt ? new Date(props.answerCloseAt).getTime() - Date.now() : undefined);
  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => { setTimeLeft(timeLeft - 1000) }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);


  return (
    <Typography className={classes.questionCloseAlert} variant="h6">{`解答倒數中 (${Math.floor(moment.duration(timeLeft).asHours())}:${moment.duration(timeLeft).minutes().toString().padStart(2, '0')}:${moment.duration(timeLeft).seconds().toString().padStart(2, '0')})`}</Typography>
  );
}

function QuestionPage() {
  const { data: appSettings } = useQuery(APP_SETTINGS);
  const [localizedDatas, setLocalizedDatas] = useState({});
  useQuery(LOCALIZED_DATAS, {
    onCompleted: data => {
      setLocalizedDatas(data.localizedDatas.reduce((obj, item) => {
        obj[item.key] = item
        return obj
      }, {}));
    },
  });
  const [unansweredQuestions, setUnansweredQuestions] = useState();
  const [getUnansweredQuestions, { loading: loadingUnansweredQuestions }] = useLazyQuery(UNANSWERED_QUESTIONS, { pollInterval: process.env.REACT_APP_POLL_INTERVAL, notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache', onCompleted: data => {
    setUnansweredQuestions(data);
    if (questionId && data.unansweredQuestions.filter(v => v.id === questionId).length === 0) getUnansweredQuestion(undefined);
  }});
  useEffect(() => {
    getUnansweredQuestions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [myAnswers, setMyAnswers] = useState();
  const [getMyAnswers, { loading: loadingMyAnswers }] = useLazyQuery(MY_ANSWERS, { pollInterval: process.env.REACT_APP_POLL_INTERVAL, notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache', variables: { orderBy: 'updatedAt_DESC' }, onCompleted: data => {
    setMyAnswers(data);
    if (questionAnswerId) getMyAnswer({ variables: { where: { id: questionAnswerId }}});
  }});

  const onCompletedStartAnswerQuestion = data => {
    setQuestionListTab(1);

    getMyAnswers();
    showMyAnswer(data.startAnswerQuestion.id, { myAnswers: [ data.startAnswerQuestion ]});

    setUnansweredQuestion(undefined);
    getUnansweredQuestions();
  };

  const updateQuestionItem = (questionAnswerId, answerCloseAt) => {
    let index;
    if (myAnswers) {
      for (let i = 0; i < myAnswers.myAnswers.length; i++) {
        if (questionAnswerId === myAnswers.myAnswers[i].id) {
          index = i;
          break;
        }
      }
    }
    let newMyAnswers = { myAnswers: [{ ...myAnswers.myAnswers[index], question: { ...myAnswers.myAnswers[index].question, answerCloseAt } }].concat(myAnswers.myAnswers.slice(0, index), myAnswers.myAnswers.slice(index + 1)) };
    if (index) setMyAnswers(newMyAnswers);
  };

  const [unansweredQuestion, setUnansweredQuestion] = useState();
  const [getUnansweredQuestion, { loading: loadingUnansweredQuestion }] = useLazyQuery(UNANSWERED_QUESTION, { fetchPolicy: 'no-cache', onCompleted: data => setUnansweredQuestion(data) });
  const [myAnswer, setMyAnswer] = useState();
  const [getMyAnswer, { loading: loadingMyAnswer }] = useLazyQuery(MY_ANSWER, { fetchPolicy: 'no-cache', onCompleted: data => {
    setMyAnswer(data);
    if (data.myAnswers) markQuestionAnswerRead({ variables: { answerId: data.myAnswers[0].id, readUntil: new Date().toISOString() }});
  }});

  const [markQuestionAnswerRead] = useMutation(MARK_QUESTION_ANSWER_READ, {
    onCompleted: data => {
      setMyAnswers({
        myAnswers: myAnswers.myAnswers.map(v => {
          if (v.id === data.markQuestionAnswerRead.id) v.doctorUnreadContentCount = data.markQuestionAnswerRead.doctorUnreadContentCount;
          return v
        })
      });
    }
  });

  const classes = useStyles();

  const [questionListTab, setQuestionListTab] = useState(0);
  const switchQuestionListTab = (event, newValue) => {
    setQuestionListTab(newValue);

    if (newValue === 0) {
      getUnansweredQuestions();
    } else if (newValue === 1) {
      getMyAnswers();
    }
  };
  const [questionId, setQuestionId] = useState();
  const showUnansweredQuestion = (questionId) => {
    setQuestionId(questionId);

    if (questionId) {
      getUnansweredQuestion({ variables: { where: { id: questionId }}});
    } else {
      setUnansweredQuestion(undefined);
    }
  }

  const [questionAnswerId, setQuestionAnswerId] = useState();
  const showMyAnswer = (questionAnswerId, myAnswer) => {
    setQuestionAnswerId(questionAnswerId);

    if (questionAnswerId) {
      if (myAnswer) {
        setMyAnswer(myAnswer);
      } else {
        getMyAnswer({ variables: { where: { id: questionAnswerId }}});
      }
    } else {
      setMyAnswer(undefined);
    }
  }

  return (
    <>
      <CustomAppBar title="答問中心" />
      <div className={classes.contentWrapper}>
        <div className={classes.questionListWrapper}>
        <Tabs
          value={questionListTab}
          onChange={switchQuestionListTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="待答問題" />
          <Tab label="我的回答" />
        </Tabs>
        <div hidden={questionListTab !== 0} className={classes.questionList}>
          <QuestionList
            questionListTab={questionListTab}
            questionId={questionId}
            loading={loadingUnansweredQuestions}
            data={unansweredQuestions}
            handleClick={showUnansweredQuestion}
          />
        </div>
        <div hidden={questionListTab !== 1} className={classes.questionList}>
          <QuestionList
            questionListTab={questionListTab}
            questionAnswerId={questionAnswerId}
            loading={loadingMyAnswers}
            data={myAnswers}
            handleClick={showMyAnswer}
          />
        </div>
        </div>
        <div className={classes.questionWrapper}>
          {questionListTab === 0 && (
            <Question localizedDatas={localizedDatas} loading={loadingUnansweredQuestion} data={unansweredQuestion} onCompletedStartAnswerQuestion={onCompletedStartAnswerQuestion} />
          )}
          {questionListTab === 1 && (
            <Question localizedDatas={localizedDatas} loading={loadingMyAnswer} data={myAnswer} appSettings={appSettings} updateQuestionItem={updateQuestionItem} />
          )}
        </div>
      </div>
    </>
  );
}

export default QuestionPage;
