import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-hooks';
import { createUploadLink } from'apollo-upload-client';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import PrivateRoute from './components/PrivateRoute';
import LoginPage from './views/LoginPage';
import QuestionPage from './views/QuestionPage';

const client = new ApolloClient({
  link: createUploadLink({
    uri: process.env.REACT_APP_API_ENDPOINT,
    credentials: 'include',
  }),
  cache: new InMemoryCache(),
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#5498e0',
    },
    secondary: {
      main: '#366ea9',
    },
  },
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", "微軟正黑體", "Microsoft JhengHei", sans-serif`,
  },
});

function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/question" />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <PrivateRoute path="/question">
              <QuestionPage />
            </PrivateRoute>
          </Switch>
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
